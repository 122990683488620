<template>
  <b-form @submit.prevent="uploadFile()">
    <b-row align-h="center" class="mb-5 mt-4">
      <!-- half mobile half desktop -->
      <b-col cols="12">
        <b-navbar fixed="top" class="sidenav">
          <b-container fluid>
            <b-row align-v="center" style="width: 100%">
              <b-col cols="3">
                <b-button
                  class="back-btn"
                  size="lg"
                  to="/personal-details"
                  variant="link"
                  ><img src="@/assets/images/back_arrow.svg" alt=""
                /></b-button>
              </b-col>
              <b-col cols="6" class="d-md-none text-center">Upload Files</b-col>
              <b-col cols="3" class="d-md-none text-right">
                <h6 class="step text-uppercase mb-0">Step 2 of 2</h6>
              </b-col>
            </b-row>
          </b-container>
        </b-navbar>
      </b-col>
      <!-- desktop view -->
      <b-col cols="12" md="3">
        <b-row
          class="d-none d-sm-none d-md-flex"
          style="position: fixed; height: calc(100vh - 300px)"
        >
          <b-col cols="12" align-self="start" class="text-primary">
            Upload Files
          </b-col>
          <b-col cols="12" align-self="end">
            <h6 class="step text-uppercase">Step 2 of 2</h6>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="7">
        <b-row>
          <b-col cols="12">
            <h1 class="mb-6 heading">Next, let’s upload some files.</h1>
            <b-alert :show="alert.show" :variant="alert.type">{{
              alert.message
            }}</b-alert>
          </b-col>
          <b-col cols="12" md="4">
            <h3 class="mb-2 bold text-primary">Profile Image</h3>
            <p class="text-muted">PNG or JPG. Maximum file size is 5MB.</p>
            <div
              @click="$refs.file.click()"
              ref="preview"
              id="preview"
              :disabled="processing"
              style="overflow: hidden"
              class="avatar avatar-preview bg-primary rounded-circle"
            >
              <img
                v-if="showPreview"
                :src="imagePreview"
                class="avatar-img previewOnly"
              />
              <template v-else>
                <img
                  v-if="avatarImg"
                  :src="avatarImg"
                  class="avatar-img recent"
                />
              </template>
            </div>
            <!-- <div @click="removeAvatar()" class="remove-avatar">
              <b-avatar variant="primary" icon="x" size="20px"></b-avatar>
            </div> -->
            <div>
              <b-button
                pill
                size="lg"
                variant="outline-primary"
                class="mt-3 mb-4 photo-btn"
              >
                <input
                  type="file"
                  id="file"
                  ref="file"
                  accept="image/*"
                  class="hide-file-input"
                  :disabled="processing"
                  v-on:change="handleFileUpload()"
                />
                <label for="file" class="mb-0">BROWSE PHOTOS</label>
              </b-button>
            </div>
          </b-col>
          <b-col cols="12" md="8">
            <h3 class="mb-2 bold text-primary">
              Licenses &amp; other documents
            </h3>
            <p class="text-muted">
              Upload your credentials to start with the dashboard
            </p>
            <dropzone
              id="dropzone"
              :options="dropzoneOptions"
              ref="uploadDocs"
              :destroyDropzone="false"
              @vdropzone-error="dropzoneOnError"
              v-on:vdropzone-file-added="fileAdded"
              v-on:vdropzone-removed-file="fileRemoved"
            ></dropzone>
          </b-col>
          <b-col cols="12" class="text-right mt-3">
            <b-button
              size="lg"
              pill
              type="submit"
              variant="primary"
              :disabled="processing"
              style="min-width: 150px"
              >{{ processing ? "Saving" : "Next" }}</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col cols="12" md="12">
        <next-step step-number="2"></next-step>
      </b-col>
    </b-row> -->
  </b-form>
</template>

<script>
import thumb from "@/assets/images/file.svg";
import Dropzone from "vue2-dropzone";
// import nextStep from "@/components/step/StepButton";
const template = () => `
  <ul class="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush">
    <li class="list-group-item">
      <div class="row align-items-center">
        <div class="col-auto">

          <!-- Image -->
          <div class="avatar">
            <div class="dz-success-mark"><i class="fe fe-check"></i></div>
            <div class="dz-error-mark"><i class="fe fe-x-circle"></i></div>
            <img class="avatar-img rounded" data-dz-thumbnail>
          </div>

        </div>
        <div class="col ml-n3">

          <!-- Heading -->
          <h4 class="mb-1" data-dz-name>...</h4>

          <!-- Progress -->
          <div class="progress progress-sm">
            <div class="progress-bar dz-upload" data-dz-uploadprogress role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <!-- Text -->
          <small class="text-muted" data-dz-size>...</small><br>
          <small class="text-muted status" data-dz-status>...</small>

        </div>
      </div>
    </li>
  </ul>
`;
import { OnboardingService } from "@/services/onboarding.service";
import { DocumentService } from "@/services/document.service";

const API_DOCS_URL = `${process.env.VUE_APP_API_URL}/document`;
export default {
  name: "document",
  components: {
    // nextStep,
    Dropzone,
  },
  data() {
    return {
      fullName: "",
      file: "",
      imagePreview: "",
      showPreview: false,
      processing: false,
      avatarImg: null,
      alert: {
        show: false,
        type: "info",
        message: "",
      },
      dropzoneOptions: {
        url: API_DOCS_URL,
        method: "post",
        headers: {
          Authorization: `Bearer `,
        },
        paramName: "document",
        // acceptedFiles: 'application/pdf',
        acceptedFiles: ".jpg,.jpeg,.png,.pdf",
        maxFilesize: 5, // MB
        maxFiles: 20,
        autoProcessQueue: false,
        previewTemplate: template(),
        addRemoveLinks: true,
        thumbnailWidth: 48,
        thumbnailHeight: 48,
      },
    };
  },
  methods: {
    alertMessage(type, message) {
      window.scrollTo(0, 0);

      this.alert = {
        type: type,
        message: message,
        show: true,
      };
    },

    dropzoneOnError(file, message, response) {
      this.alertMessage(
        "warning",
        `${file.name} ${message} ${
          file.type == "" ? "Must upload PDF / Image files." : ""
        }`
      );
    },
    removeAvatar() {
      this.imagePreview = "";
    },
    async uploadFile() {
      try {
        this.processing = true;
        this.alert.show = false;
        if (this.showPreview == true || this.avatarImg == null) {
          if (!this.file) {
            throw new Error(`Please upload your profile image`);
          }
          let formData = new FormData();
          formData.append("avatar", this.file);
          await OnboardingService.updateDoctorOnboarding(2.1, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          await this.uploadDocuments();
        } else {
          await this.uploadDocuments();
        }
      } catch (error) {
        this.alertMessage("warning", error.message);
      } finally {
        this.processing = false;
      }
    },

    async fileRemoved(file) {
      if (file.id) {
        await DocumentService.deleteDocument(file.id);
      }
    },

    fileAdded(file) {
      if (!file.id && !file.type.includes("image")) {
        var a = $(file.previewElement);
        var aa = a.find(".rounded")[0];
        $(aa).attr("src", thumb);
        $(aa).css({ width: "48px", height: "48px;", "object-fit": "none" }); //'background': 'linear-gradient(0deg, #FF8AA1, #FF8AA1)'});
      }
    },
    uploadDocuments() {
      let files = this.$refs.uploadDocs.dropzone.files;
      let allUploadPromise = [];
      files.forEach((file) => {
        if (!file.id) {
          var mform = new FormData();
          mform.append("document", file);

          allUploadPromise.push(
            OnboardingService.updateDoctorOnboarding(2.2, mform, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: function (progressEvent) {
                let uploadPercentage = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
                let prevElement = $(file.previewElement);
                if (prevElement.find(".dz-upload").length)
                  $(prevElement.find(".dz-upload")[0]).css({
                    width: uploadPercentage + "%",
                  });
              }.bind(this),
            }).then((data) => {})
          );
        }
      });

      Promise.all(allUploadPromise).then((values) => {
        this.$router.push("/summary");
      });
    },

    handleFileUpload() {
      const maxAllowedSize = 5 * 1024 * 1024;
      //Set the local file variable to what the user has selected.
      this.file = this.$refs.file.files[0];
      if (file.size > maxAllowedSize) {
        return this.alertMessage("warning", "File should not exceed 5MB");
      }
      // Initialize a File Reader object
      let reader = new FileReader();
      // Add an event listener to the reader that when the file
      // has been loaded, we flag the show preview as true and set the
      // image to be what was read from the reader
      reader.addEventListener(
        "load",
        function () {
          this.showPreview = true;
          this.imagePreview = reader.result;
        }.bind(this),
        false
      );
      // Check to see if the file is not empty.
      if (this.file) {
        //  Ensure the file is an image file.
        if (/\.(jpe?g|png)$/i.test(this.file.name)) {
          // Fire the readAsDataURL method which will read the file in and
          // upon completion fire a 'load' event which we will listen to and
          // display the image in the preview.
          reader.readAsDataURL(this.file);
        }
      }
    },

    getuser() {
      this.$store
        .dispatch("user/getUser")
        .then((data) => {
          if (data.onboardingstatus === "DONE") return this.$router.push("/");
          this.avatarImg = data.profileUrl;

          this.$store
            .dispatch("user/getDocuments")
            .then((data) => {
              if (data.length > 0) {
                this.documents = data;
                for (var i = 0; i <= this.documents.length; i++) {
                  var document = this.documents[i];
                  var filename = document.filename;
                  var type = document.type;
                  var status = document.status;
                  var size = document.size;
                  var id = document.id;

                  var mockFile = {
                    name: filename,
                    size: size,
                    type: type,
                    id: id,
                    status: status,
                  }; //Any name, size.

                  if (type.includes("image"))
                    this.$refs.uploadDocs.manuallyAddFile(
                      mockFile,
                      document.previewURL
                    );
                  else this.$refs.uploadDocs.manuallyAddFile(mockFile, thumb);

                  var a = $(
                    this.$refs.uploadDocs.dropzone.previewsContainer.lastChild
                  );

                  if (a.find(".progress").length)
                    $(a.find(".progress")[0]).css({ display: "none" });

                  a.find(".status")[0].innerHTML = status;

                  if (!type.includes("image")) {
                    var aa = a.find(".rounded")[0];
                    $(aa).attr("src", thumb);
                    $(aa).css({
                      width: "48px",
                      height: "48px;",
                      "object-fit": "none",
                    });
                  }
                }
              }
            })
            .catch((err) => {
              if (err.message) {
                this.err = err.message;
              }
            });
        })
        .catch((error) => {
          if (error.message) {
            return this.alertMessage("warning", error.message);
          }
        });
    },
  },

  mounted() {
    this.getuser();
  },
};
</script>

<style lang="scss">
.remove-avatar {
  position: relative;
  top: -8px;
  margin-left: -15px;
  display: inline;
}

.avatar-preview {
  width: 9rem;
  height: 9rem;
}

.card {
  &.no-bg-shadow {
    background-color: transparent;
    box-shadow: none;
    border: 0;
  }
}

.dz-success-mark,
.dz-error-mark {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 1.5em;
  align-items: center;
  justify-content: center;
}

.dropzone .dz-preview.dz-success .dz-success-mark,
.dropzone .dz-preview.dz-error .dz-error-mark {
  display: flex;
}
</style>
