var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.uploadFile()
        },
      },
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-5 mt-4", attrs: { "align-h": "center" } },
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-navbar",
                { staticClass: "sidenav", attrs: { fixed: "top" } },
                [
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { "align-v": "center" },
                        },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "3" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "back-btn",
                                  attrs: {
                                    size: "lg",
                                    to: "/personal-details",
                                    variant: "link",
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/back_arrow.svg"),
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "d-md-none text-center",
                              attrs: { cols: "6" },
                            },
                            [_vm._v("Upload Files")]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "d-md-none text-right",
                              attrs: { cols: "3" },
                            },
                            [
                              _c(
                                "h6",
                                { staticClass: "step text-uppercase mb-0" },
                                [_vm._v("Step 2 of 2")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c(
                "b-row",
                {
                  staticClass: "d-none d-sm-none d-md-flex",
                  staticStyle: {
                    position: "fixed",
                    height: "calc(100vh - 300px)",
                  },
                },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "text-primary",
                      attrs: { cols: "12", "align-self": "start" },
                    },
                    [_vm._v(" Upload Files ")]
                  ),
                  _c("b-col", { attrs: { cols: "12", "align-self": "end" } }, [
                    _c("h6", { staticClass: "step text-uppercase" }, [
                      _vm._v("Step 2 of 2"),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "7" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("h1", { staticClass: "mb-6 heading" }, [
                        _vm._v("Next, let’s upload some files."),
                      ]),
                      _c(
                        "b-alert",
                        {
                          attrs: {
                            show: _vm.alert.show,
                            variant: _vm.alert.type,
                          },
                        },
                        [_vm._v(_vm._s(_vm.alert.message))]
                      ),
                    ],
                    1
                  ),
                  _c("b-col", { attrs: { cols: "12", md: "4" } }, [
                    _c("h3", { staticClass: "mb-2 bold text-primary" }, [
                      _vm._v("Profile Image"),
                    ]),
                    _c("p", { staticClass: "text-muted" }, [
                      _vm._v("PNG or JPG. Maximum file size is 5MB."),
                    ]),
                    _c(
                      "div",
                      {
                        ref: "preview",
                        staticClass:
                          "avatar avatar-preview bg-primary rounded-circle",
                        staticStyle: { overflow: "hidden" },
                        attrs: { id: "preview", disabled: _vm.processing },
                        on: {
                          click: function ($event) {
                            return _vm.$refs.file.click()
                          },
                        },
                      },
                      [
                        _vm.showPreview
                          ? _c("img", {
                              staticClass: "avatar-img previewOnly",
                              attrs: { src: _vm.imagePreview },
                            })
                          : [
                              _vm.avatarImg
                                ? _c("img", {
                                    staticClass: "avatar-img recent",
                                    attrs: { src: _vm.avatarImg },
                                  })
                                : _vm._e(),
                            ],
                      ],
                      2
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mt-3 mb-4 photo-btn",
                            attrs: {
                              pill: "",
                              size: "lg",
                              variant: "outline-primary",
                            },
                          },
                          [
                            _c("input", {
                              ref: "file",
                              staticClass: "hide-file-input",
                              attrs: {
                                type: "file",
                                id: "file",
                                accept: "image/*",
                                disabled: _vm.processing,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleFileUpload()
                                },
                              },
                            }),
                            _c(
                              "label",
                              { staticClass: "mb-0", attrs: { for: "file" } },
                              [_vm._v("BROWSE PHOTOS")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "8" } },
                    [
                      _c("h3", { staticClass: "mb-2 bold text-primary" }, [
                        _vm._v(" Licenses & other documents "),
                      ]),
                      _c("p", { staticClass: "text-muted" }, [
                        _vm._v(
                          " Upload your credentials to start with the dashboard "
                        ),
                      ]),
                      _c("dropzone", {
                        ref: "uploadDocs",
                        attrs: {
                          id: "dropzone",
                          options: _vm.dropzoneOptions,
                          destroyDropzone: false,
                        },
                        on: {
                          "vdropzone-error": _vm.dropzoneOnError,
                          "vdropzone-file-added": _vm.fileAdded,
                          "vdropzone-removed-file": _vm.fileRemoved,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-right mt-3", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: { "min-width": "150px" },
                          attrs: {
                            size: "lg",
                            pill: "",
                            type: "submit",
                            variant: "primary",
                            disabled: _vm.processing,
                          },
                        },
                        [_vm._v(_vm._s(_vm.processing ? "Saving" : "Next"))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }